<template>
  <fragment>
    <wx-form-container ref="puDataExportForm" class="wizard pb-10">
      <fieldset>
        <v-row>
          <v-col cols="12">
            <!-- - - - - - - - - -     Start & End dates     - - - - - - - - - - - - -->
            <h2 class="legend-header">{{ $t("productionDataExport.queryPeriod") }}</h2>
            <wx-date-range-picker
              :timezone="detectedTimeZone"
              :week-first-day="activeFactoryWeekFirstDay"
              :max-past-months="maxPastMonths"
              :error-messages="dateRangeErrorMessages"
              @newDateRange="setDateRange"
              class="mt-1 mb-6"
            />
          </v-col>
          <v-col cols="12">
            <!-- - - - - - - - - -     Production Units     - - - - - - - - - - - - -->
            <h2 class="legend-header">{{ $t("productionDataExport.productionUnits") }}</h2>
            <wx-production-unit-multi-selector
              v-model="selectedProductionUnits"
              :is-multiple-factories="false"
              :available-production-units="this.activeFactoryProductionUnits"
              class="mt-1 mb-6 pu-selector"
            />
          </v-col>
        </v-row>
      </fieldset>
      <fieldset class="footer-action-buttons">
        <v-row v-if="isSpanish">
          <v-col cols="12" class="order-sm-2 field-col d-flex align-center justify-end">
            <v-icon color="warning">$alertIcon</v-icon>
            <span class="ml-2">{{ $t("productionDataExport.spanishNotSupported") }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4" class="order-sm-2 field-col d-flex align-center justify-end">
            <wx-btn-standard color="primary" class="my-3" @click="fetchData()">
              {{ $t("common.downloadButton") }}<v-icon right>mdi-download</v-icon>
            </wx-btn-standard>
          </v-col>
          <v-col cols="12" sm="8" class="order-sm-1 field-col">
            <div v-if="exportInProgress" class="d-flex align-center">
              <v-progress-circular color="primary" :size="50" :width="7" indeterminate />
              <p class="ml-3 mb-0">{{ $t("common.inProgressNotification") }}</p>
            </div>
          </v-col>
        </v-row>
      </fieldset>
    </wx-form-container>
  </fragment>
</template>

<script>
import WxFormContainer from "@/components/ui/WxFormContainer";
import WxBtnStandard from "@/components/ui/WxBtnStandard";
import WxDateRangePicker from "@/components/ui/WxDateRangePicker";
import WxProductionUnitMultiSelector from "@/components/ui/WxProductionUnitMultiSelector";
import { mapActions, mapGetters } from "vuex";
import ProductionDataExportService from "@/components/productiondataexport/ProductionDataExportService";
import ErrorHandling from "@/components/ErrorHandling";
import moment from "moment-timezone";
import TimelineService from "@/components/dashboard/TimelineService";

export default {
  name: "ProductionDataExportMenu",
  components: {
    WxFormContainer,
    WxBtnStandard,
    WxDateRangePicker,
    WxProductionUnitMultiSelector,
  },
  data() {
    return {
      selectedProductionUnits: [],

      dateRange: null,
      exportInProgress: false,

      dateRangeErrorMessages: [],
    };
  },
  computed: {
    ...mapGetters("navigation", ["activeFactory", "activeFactoryProductionUnits"]),
    ...mapGetters("user", ["language", "isSpanish", "isWorximityAdmin", "worximityAdminAllowedCoverageInMonths"]),
    maxPastMonths() {
      if (this.isWorximityAdmin) {
        return this.worximityAdminAllowedCoverageInMonths;
      }
      return 1;
    },
    activeFactoryWeekFirstDay() {
      return this.activeFactory?.weekFirstDay ? this.activeFactory.weekFirstDay : "monday";
    },
    detectedTimeZone() {
      if (this.activeFactory?.timezone) {
        return this.activeFactory.timezone;
      } else {
        const zone = moment.tz.guess(true);
        return zone === null || zone === undefined ? "America/Montreal" : zone;
      }
    },
  },
  watch: {
    activeFactoryProductionUnits() {
      if (this.activeFactoryProductionUnits) {
        this.selectedProductionUnits = this.activeFactoryProductionUnits;
      }
    },
  },
  methods: {
    ...mapActions("operation", ["showOperationError"]),
    resetDateRangeValidations() {
      this.dateRangeErrorMessages = [];
    },
    validateDateRange() {
      this.resetDateRangeValidations();
      if (this.dateRange) {
        if (this.dateRange.startDate === null || this.dateRange.startDate === undefined) {
          this.dateRangeErrorMessages = [this.$t("productionDataExport.errors.noStartDate")];
          return false;
        }
        if (this.dateRange.endDate === null || this.dateRange.endDate === undefined) {
          this.dateRangeErrorMessages = [this.$t("productionDataExport.errors.noEndDate")];
          return false;
        }
      }
      return true;
    },
    setDateRange(dateRange) {
      if (!this.validateDateRange()) return;
      // grab the first production unit from the factory
      // this is just so the API can fetch the productionDayMinutesFromMidnight and factoryTimeZone in the backend
      // every production unit in the same factory will have those same values so it doesn't matter which one
      if (!this.activeFactoryProductionUnits || this.activeFactoryProductionUnits.length === 0) return;
      const productionUnit = this.activeFactoryProductionUnits[0];
      TimelineService.getBusinessDayRange(productionUnit.id, dateRange.startDate, dateRange.endDate)
        .then((httpResponse) => {
          let newDateRange = { ...dateRange };
          newDateRange.startDate = httpResponse.data.start_date;
          newDateRange.endDate = httpResponse.data.end_date;
          this.dateRange = newDateRange;
        })
        .catch(() => {
          this.showOperationError(this.$t("common.errors.dateTimeConversion"));
          this.dateRange = null;
        });
    },
    fetchData() {
      if (!this.activeFactory) return;

      let isValid = this.$refs.puDataExportForm.validate();
      if (!isValid) return;
      this.exportInProgress = true;
      let startTime = this.dateRange?.startDate;
      let endTime = this.dateRange?.endDate;
      let productionUnitIds = this.selectedProductionUnits.map((pu) => pu.id);
      const requestPayload = {
        factory_id: this.activeFactory.id,
        start_time: startTime,
        end_time: endTime,
        production_unit_ids: productionUnitIds,
      };
      // TODO Remove this as soon as the Production Unit Export supports the Spanish
      let lang = this.language;
      if (this.isSpanish) {
        lang = "en";
      }
      ProductionDataExportService.fetchProductionData(requestPayload, lang)
        .then((httpResponse) => this.handleDataExportResponse(httpResponse))
        .catch((httpResponse) => this.handleDataExportError(httpResponse));
    },
    handleDataExportResponse(httpResponse) {
      let fileName = this.extractFileName(httpResponse.headers["content-disposition"]);
      let file = window.URL.createObjectURL(new Blob([httpResponse.data]));
      let docUrl = document.createElement("a");
      docUrl.href = file;
      docUrl.setAttribute("download", fileName);
      document.body.appendChild(docUrl);
      docUrl.click();
      document.body.removeChild(docUrl);
      this.exportInProgress = false;
    },
    handleDataExportError(httpResponse) {
      this.exportInProgress = false;
      this.showOperationError(ErrorHandling.buildErrorsMessages(httpResponse, this.getErrorMessage));
      this.close();
    },
    extractFileName(disposition){
      if (disposition == null || disposition == undefined) {
        return "Export.xlsx"
      } else if(disposition.indexOf("attachment; filename*=UTF-8''") > -1) {
        return decodeURIComponent(disposition.replace("attachment; filename*=UTF-8''", ""))
      }
      return disposition.replace("attachment; filename=\"", "").replace("\"", "")
    },
    getErrorMessage(code) {
      return this.$t("common.errors.default", { code: code });
    },
  },
};
</script>
