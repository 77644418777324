import axios from "axios";
import { getTokenRedirect } from "@/authentication/authRedirect";
import { tokenRequest } from "@/authentication/authConfig";

class ProductionDataExportService {
  fetchProductionData(payload, language) {
    const http = axios.create({
      baseURL: process.env.VUE_APP_DASHBOARD_API_BASE_URL,
      method: "POST",
      responseType: "blob",
    });
    http.interceptors.request.use(async (req) => {
      const token = await getTokenRedirect(tokenRequest);
      req.headers.Authorization = "Bearer " + token;
      if (req.data) req.headers["Content-Type"] = "application/json";
      return req;
    });

    return http.post(`/export?lang=${language}`, payload);
  }
}

export default new ProductionDataExportService();
